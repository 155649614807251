type linksTypeDescription =
  | string
  | { [phone: string]: string; email: string; fb: string };

type langType = {
  [locale: string]: {
    [page: string]: {
      [restaurant: string]: {
        [links: string]: {
          [menu: string]: linksTypeDescription;
          order: string;
          reserve: string;
          ig: string;
          whatsapp: string;
          expReview: string;
          contact: {
            [phone: string]: string;
            email: string;
            fb: string;
          };
        };
      };
    };
  };
};

const vialettoContact = {
  email: "vialettopizzeria@gmail.com",
  phone: "tel:6048280978",
  fb: "https://www.facebook.com/vialettopizzeria"
}

export const lang: langType = {
  "en-US": {
    home: {},
    restaurant: {
      amauz: {
        links: {
          menu: "menu",
          order: "order",
          reserve: "reserve",
          ig: "Instagram",
          whatsapp: "Whatsapp",
          expReview: "Tell us about your experience",
          contact: {
            phone: "",
            email: "",
            fb: "",
          },
        },
      },
      vialetto: {
        links: {
          menu: "menu",
          order: "order",
          reserve: "reserve",
          ig: "Instagram",
          whatsapp: "Whatsapp",
          expReview: "Tell us about your experience",
          contact: vialettoContact,
        },
      },
      slou: {
        links: {
          menu: "menu",
          order: "order",
          reserve: "reserve",
          ig: "Instagram",
          whatsapp: "Whatsapp",
          expReview: "Tell us about your experience",
          contact: {
            phone: "",
            email: "",
            fb: "",
          },
        },
      },
    },
  },
  "es-ES": {
    home: {},
    restaurant: {
      amauz: {
        links: {
          menu: "menú",
          contact: {
            phone: "",
            email: "",
            fb: "",
          },
          order: "ordena en línea",
          reserve: "reservas",
          ig: "instagram",
          whatsapp: "whatsapp",
          expReview: "cuéntanos tu experiencia",
        },
      },
      vialetto: {
        links: {
          menu: "menú",
          contact: vialettoContact,
          order: "ordena en línea",
          reserve: "reservas",
          ig: "instagram",
          whatsapp: "whatsapp",
          expReview: "cuéntanos tu experiencia",
        },
      },
      slou: {
        links: {
          menu: "menú",
          contact: {
            phone: "",
            email: "",
            fb: "",
          },
          order: "ordena en línea",
          reserve: "reservas",
          ig: "instagram",
          whatsapp: "whatsapp",
          expReview: "cuéntanos tu experiencia",
        },
      },
    },
  },
};
