import * as React from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// import the mapbox-gl styles so that the map is displayed correctly

function MapboxMap() {
  // this is where the map instance will be stored after initialization
  const [map, setMap] = React.useState<mapboxgl.Map>();

  // React ref to store a reference to the DOM node that will be used
  // as a required parameter `container` when initializing the mapbox-gl
  // will contain `null` by default
  const mapNode = React.useRef(null);

  React.useEffect(() => {
    const node = mapNode.current;
    // if the window object is not found, that means
    // the component is rendered on the server
    // or the dom node is not initialized, then return early
    if (typeof window === "undefined" || node === null) return;
    const center = [-76.63428727032208, 7.882857702838888];

    // otherwise, create a map instance
    const mapboxMap = new mapboxgl.Map({
      container: node,
      cooperativeGestures: true,
      accessToken: process.env.NEXT_PUBLIC_MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/streets-v11",
      // @ts-ignore
      center,
      zoom: 20,
    });

    //@ts-ignore
    mapboxMap.addControl(new mapboxgl.NavigationControl());

    // Create a default Marker and add it to the map.
    //@ts-ignore
    const marker1 = new mapboxgl.Marker()
      // @ts-ignore
      .setLngLat(center)
      .addTo(mapboxMap);

    // save the map object to React.useState
    setMap(mapboxMap);

    return () => {
      mapboxMap.remove();
    };
  }, []);

  return (
    <div className="w-full p-4">
      <a
        href="https://www.google.com/maps/place/AMAUZ+Restaurante/@7.8825814,-76.6364867,17z/data=!3m1!4b1!4m5!3m4!1s0x8e500d7b118cb6dd:0x71841f355d477c31!8m2!3d7.8825814!4d-76.634298"
        className="no-underline font-bold inline-block uppercase text-white mb-2 text-xs"
        rel="noreferrer noopener"
        target="_blank"
      >
        Ver en Google Maps
      </a>
      <div
        ref={mapNode}
        style={{ height: "42vh" }}
        className="w-100 xl:w-3/4 rounded-md shadow-lg"
      />
      ;
    </div>
  );
}

export default MapboxMap;
